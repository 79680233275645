<template lang="pug">
TableV2(
  :isViewAdd="false"
  :headers="headers"
  :items="item.results"
  :pageCount="item.count"
  :currentPage="item.current"
  :querySearch="getTranslationAgencies"
  :isLoading="isLoading"
  :actions="actions")
  TranslateAgencyAdd(slot="AddBlock" actionType="add")
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    TranslateAgencyAdd: () => import('./Form.vue')
  },
  data () {
    return {
      headers: [
        { value: 'id', text: 'ID', sortable: false },
        { value: 'name', text: this.$t('translationAgency'), sortable: false },
        { value: 'languages', text: this.$t('language'), sortable: false },
        { value: 'event', width: '24px', sortable: false }
      ],
      actions: [
        { id: 1,
          tooltip: 'tooltip.go',
          action: item => item.id && this.$router.push(`/back-office/translate-agencies/${item.id}`),
          color: 'blue',
          name: 'mdi-arrow-right',
          isView: true }
      ]
    }
  },
  computed: {
    ...mapState({
      item: state => state.backoffice.translationAgency,
      isLoading: state => state.backoffice.isLoading
    })
  },
  methods: {
    ...mapActions(['getTranslationAgencies'])
  }
}
</script>
